@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@600&display=swap');

#login-box{
    background-image: url(../Images//discord_login_background.png);
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#login-box-sub{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    background-color: rgb(40,40,40);
    color: white;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
}
#login-box-sub-sub{
    display: flex;
    flex-direction: column;
    width: 16rem;
    padding: 1rem 0.5rem;
}
#login-box-sub-sub h3{
    font-family: 'Heebo', sans-serif;
    font-size: 2rem;
}
#login-box-sub-sub p{
    color: rgb(2305,235,235);
    font-size: 0.8rem;
}
#login-box-sub-sub label{
    font-family: 'Heebo', sans-serif;
    font-size: 1rem;
}
#login-QR-code{
    background-color: white;
    width: 10rem;
    height: 10rem;
    border-radius: 1rem;
}
#login-QR-code img{
    width: 9rem;
    height: 9rem;
    margin: 0.5rem;
}
#login-box-sub-sub a{
    color: #00b7ff;
    font-size: 0.85rem;
    cursor: pointer;
}#login-box-sub-sub a:hover{
    text-decoration: underline;
}
#login-box-sub-sub input{
    background-color: rgb(60,60,60);
    border: none;
    outline: none;
    color: white;
    font-family: 'Heebo', sans-serif;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 1.7rem;
}
#login-btn{
    background-color: rgb(78, 78, 255);
    font-family: 'Heebo', sans-serif;
    border: none;
    height: 2.5rem;
    color: white;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    transition: 0.5s;
    font-size: 1.15rem;
    
}#login-btn:hover{ transform: scale(1.1); }
#admin-panel{
    background-color: rgb(0, 0, 0);
    font-family: 'Heebo', sans-serif;
    border: none;
    height: 2.5rem;
    color: white;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
    transition: 0.5s;
    font-size: 1.15rem;
    
}#admin-panel:hover{ transform: scale(1.1); }
#login-form{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#login-box-sub-sub-2{
    width: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#login-box-sub-sub-2 h3{
    margin: 1rem 0;
}#login-register a{
    text-decoration: none;
}