@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@600&display=swap');

#register-div{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../Images/discord_login_background.png);
    background-size: cover;
    font-family: 'Heebo', sans-serif;
}
#register-box{
    background-color: rgb(60,60,60);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
}#register-box a{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #00b7ff;
    font-size: 0.85rem;
    cursor: pointer;
}#register-box a:hover{
    text-decoration: underline;
}#register-box div label{
    color: rgb(190,190,190);
    font-size: 0.8rem;
}#register-box div{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
}#register-box div input{
    background-color: rgb(40,40,40);
    color: white;
    border: solid black 0.1rem;
    outline: none;
    font-family: 'Heebo', sans-serif;
    width: 27rem;
    height: 2.15rem;
    margin: 0.5rem 0;
}#register-box h3{
    font-size: 1.5rem;
}
#register-btn{
    width: 100%;
    height: 2.5rem;
    color: white;
    background-color: rgb(78, 78, 255);
    border: none;
    font-family: 'Heebo', sans-serif;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.25rem;
    transition: 0.5s;
}#register-btn:hover{
    transform: scale(1.1);
}
#register-box-a-div{
    width: 100%;
    padding-top: 0.4rem;
}#register-box-a-div a{
    text-decoration: none;
}