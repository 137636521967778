*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@600&display=swap');

nav{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 10%;
}
#home-div{
    color: white;
    background-image: url("../Images/home_bg.png");
    height: 100vh;
    background-size: 1500px 800px;
    font-family: 'Heebo', sans-serif;
    background-repeat: no-repeat;
}
#home-nav-img{
    width: rem;
    height: 3rem;
}
nav div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-div-a{
    cursor: pointer;
    margin: 0 0.7rem; 
    color: white;  
}
.login-btn{
    background-color: white;
    border-radius: 5rem;
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    transition: 0.4s;
}.login-btn:hover{
    transform: scale(1.1);
}.login-btn a{
    text-decoration: none;
    color: #06e056;
}
.reload-home{
    cursor: pointer;
}
.reload-home span{
    font-size: 1.5rem;
}
#home-body{
    display: flex;
    flex-direction: column;
    height: 50%;
    align-items: center;
    justify-content: center;
    padding: 0 18rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.9rem;

}
#home-btns-div{
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#open-in-browser-btn, #download-mac-btn{
    margin: 0.5rem 2rem;
    border-radius: 2.5rem;
    width: 22rem;
    height: 2.5rem;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}#open-in-browser-btn:hover , #download-mac-btn:hover{
    transform: scale(1.1);
}
#download-mac-btn img{
    height: 2rem;
}
#download-mac-btn{
    background-color: white;
    color: rgb(0, 0, 0); 
    
} 
#download-mac-btn a{
    background-color: white;
    color: #06e056;
    text-decoration: none;
    
}
#open-in-browser-btn{
    background-color: rgb(25,25,25);
    color: white;
}
#home-body-heading{
    font-family: 'Heebo', sans-serif;
    font-size: 4rem;
}#open-in-browser-btn a{
    text-decoration: none;
    color: white;
}
