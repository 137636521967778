@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@500&family=Heebo:wght@600&display=swap');

#server{
    display: grid;
    grid-template-columns: 4% 15% 65% 16%;
    height: 100vh;
}

/*left side styling*/
#server-left{
    display: grid;
    grid-template-rows: 90% 10%;
    background-color: rgb(40,40,40);
    color: white;
}
#server-bottom-div{
    background-color: rgb(25,25,25);
    display: flex;
}
.server-bottom-currently-speaking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.75rem;
}
.server-bottom-images{
    display: flex;
    align-items: center;
}.server-bottom-images img{
    cursor: pointer;
    margin: 0 0.2rem;
}#server-bottom-pre{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.available-channel{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    padding: 0.25rem 0.35rem;
    margin: 0.35rem 0.5rem;
    border-radius: 0.25rem;
}.available-channel:hover{
    background-color: rgb(80,80,80);
}.not-available-channel{
    color: rgb(130,130,130);
    display: flex;
    align-items: center;
    cursor: no-drop;
    transition: 0.2s;
    padding: 0.25rem 0.35rem;
    margin: 0.35rem 0.5rem;
    border-radius: 0.25rem;
}
#server-new-line{
    font-size: 0.9rem;
    font-family: 'Heebo', sans-serif;
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 0.35rem 0.6rem;
}#server-text-channel{
    font-family: 'Heebo', sans-serif;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    padding: 0.35rem 0.6rem;
}#server-new-line img{
    cursor: pointer;
}#server-text-channel img{
    width: 7.5%;
}
.available-channel, .not-available-channel{
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 0.9rem;
}



/*middle side styling*/
#server-middle{
    background-color: rgb(60,60,60);
    color: white;
}
#server-middle-header{
    display: grid;
    grid-template-columns: 1fr 1fr;
}#server-middle-header-1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.35rem;
}#server-middle-header-2{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}#server-middle-header-1 span{
    font-family: 'Be Vietnam Pro', sans-serif;
    margin: 0 0.5rem;
}#server-middle-header-2 img{
    margin: 0 0.35rem;
    cursor: pointer;
    padding: 0.3rem;
}#server-middle-header-2 div{
    border-radius: 0.4rem;
    transition: 0.25s;
    padding: 0.3rem;
}#server-middle-header-2 div:hover{
    background-color: rgb(80,80,80);
}

#server-middle-user-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 65%;
    background-color: rgb(60,60,60);
}#server-middle-user-area-1{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.3rem 0.75rem;
}#server-middle-user-area-2{
    display: flex;
    width: 95%;
    background-color: rgb(90,90,90);
    margin: 0.4rem;
    border-radius: 1rem;
    padding: 0.4rem 0.75rem;
}
#server-middle-user-area-2 img{
    width: 3.5%;
    cursor: pointer;
    margin: 0 0.5rem;
}#server-middle-user-area-2 input{
    width: 80%;
    background-color: rgb(90,90,90);
    border: none;
    outline: none;
    color: white;
    font-family: 'Be Vietnam Pro', sans-serif;
    padding: 0.1rem 0.5rem;
}#server-middle-user-area-2 input::placeholder{
    font-family: sans-serif;
    color: rgb(180,180,180);
}#server-middle-user-area-1 button{
    padding: 0.4rem 0.25rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: #6A5ACD    ;
    color: white;
    font-family: 'Be Vietnam Pro', sans-serif;
    transition: 0.1s;
}#server-middle-user-area-1 div{
    width: 19%;
    display: flex;
    flex-direction: column;
}#server-middle-user-area-1 button:hover{
    transform: scale(1.1);
}
#server-middle-body{
    height: 65vh;
    overflow-y: scroll;
}
::-webkit-scrollbar{
    display: none;
}


/*right style styling*/
#server-right{
    display: flex;
    flex-direction: column;
    color: white;
    background-color: rgb(60,60,60);
    padding: 0.35rem 0.5rem;
}
#server-right-header{
    display: flex;
    align-items: center;
    padding: 0.5rem 0.25rem;
    height: 10%;
}
#server-right-header div{
    display: flex;
}#server-right-header input{
    width: 65%;
    height: 1.5rem;
    background-color: rgb(30,30,30);
    color: white;
    border: none;
    outline: none;
    font-family: 'Be Vietnam Pro', sans-serif;
}#server-right-header input::placeholder{
    color: rgb(130,130,130);
    font-family: 'Be Vietnam Pro', sans-serif;
}#search-img{
    width: 1rem;
    height: 1rem;
    margin-left: 1rem;
    cursor: pointer;
}#input-div{
    background-color: rgb(25,25,25);
    padding: 0.25rem 0.35rem;
    display: flex;
    align-items: center;
    border-radius: 0.2rem;
}#server-right-img-div{
    display: flex;
    align-items: center;
    padding: 0.25rem 0.35rem;
}#server-right-img-div img{
    margin: 0 0.4rem;
    cursor: pointer;
}

#server-right-people{
    padding: 1rem 0.65rem;
    border-radius: 1rem;
    background-color: rgb(40,40,40);
    height: 87.5%;
    font-family: 'Be Vietnam Pro', sans-serif;
    color: rgb(130,130,130);
    font-size: 0.75rem;
}.person{
    display: flex;
    align-items: center;
    margin: 0.4rem 0.15rem;
    padding: 0.4rem 0.15rem;
    color: rgb(130,130,130);
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 1rem;
}.person:hover{
    background-color: rgb(25,25,25);
    color: white;
}.person span{
    margin: 0 0.35rem;
}.server-middle-texts{
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 0.8rem;
    margin: 0.5rem 1rem;
    padding: 0.5rem 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 1rem;
}.server-middle-texts span{
    font-size: 0.9rem;
    color: orange;
}.server-middle-texts:hover{
    background-color: rgb(40,40,40);
}